import {
    cards_app_school, logopedia,
    drozdov_registration_form, register_shd, register_shd_comment, register_shd_new, shd_for_teachers, shd_for_students,
    logotalk_registration_form, register_logotalk, register_logotalk_comment, register_logotalk_new, logotalk_for_teachers, logotalk_for_students
} from "../text/Literals";
import {DROZDOV, LOGOTALK} from "../service/Literals";

const CARDS_APP = 'cards_app';
export interface TrialProduct {
    name : string
    products : string[]
    trial_registration_form : string
    register_trial : string
    register_trial_comment : string
    register_trial_new : string
    product_for_teachers : string
    product_for_students : string
    downloadProduct : string
}
export interface TrialProducts {
    [key :string] : TrialProduct
}
export const trialProducts: TrialProducts = {
    drozdov: {
        name: DROZDOV,
        products: [cards_app_school, logopedia],
        trial_registration_form: drozdov_registration_form,
        register_trial: register_shd,
        register_trial_comment: register_shd_comment,
        register_trial_new: register_shd_new,
        product_for_teachers: shd_for_teachers,
        product_for_students: shd_for_students,
        downloadProduct: CARDS_APP
    },
    logotalk: {
       name: LOGOTALK,
       products: [logopedia],
       trial_registration_form: logotalk_registration_form,
       register_trial: register_logotalk,
       register_trial_comment: register_logotalk_comment,
       register_trial_new: register_logotalk_new,
       product_for_teachers: logotalk_for_teachers,
       product_for_students: logotalk_for_students,
       downloadProduct: CARDS_APP
   }
};
export default trialProducts;