import React, {FC,memo} from 'react';
import {
    EncodedObjectAdaptor,
    EnumOptionsAdaptor,
    FieldAdaptor,
    FieldDivider,
    FileFieldAdaptor,
    FormFieldAdaptor, MarkedFieldAdaptor,
    ObjectArrayAdaptor, PictureUrlAdaptor,
    SimpleOptionsFieldAdaptor,
    TagsAdaptor,
    TextFieldAdaptor,
    UniqStringField, UrlAdaptor, ValidEmailFieldAdaptor,
    VideoFieldAdaptor,
    VocabularyStringField
} from "../../adaptors/FieldAdaptor";
import {
    Button,
    Checkbox,
    Divider,
    IconName,
    InputGroup,
    Intent,
    NumericInput,
    Pre,
    TextArea
} from "@blueprintjs/core";
import {AppData, Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {getTextColor} from '../../service/getIntentColor';
import FieldSaveForm from "./FieldSaveForm";
import TagsEditField from "./TagsEditField";
import ArraySaveForm from "./ArraySaveForm";
import {Select} from "@blueprintjs/select";
import UploadFileDialog from "../tools/UploadFileDialog";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import DownloadButton from "../buttons/DownloadButton";
import {key_not_found, start_download} from "../../text/Literals";
import {mapTag} from "../../text/mapTag";
import {FileTypes, isImage, isPdf} from "../../service/FileService";
import {Txt,Text} from "../tools/Text";
import DetailedOption from "../tools/DetailedOption";

interface RequestInputFieldProps {
    adaptor : FieldAdaptor;
    intent : Intent;
    data : AppData;
    vocabulary : Vocabulary;
    videoLinks : Vocabulary;
    fileLinks : Vocabulary;
    level ?: number;
    post :()=>void;
    singleProduct ?: boolean;
}
const StringSelector = Select.ofType<string>();

function getStringSelector(adaptor : SimpleOptionsFieldAdaptor, intent : Intent, icon : IconName | MaybeElement, vocabulary : Vocabulary) {
    function getText(option : string | undefined) : string {
        if(!option) return '';
        return adaptor instanceof EnumOptionsAdaptor ? vocabulary[mapTag(option)] : option;
    }

    return <StringSelector
        popoverProps = {{
            popoverClassName : 'select-popover',
            targetTagName : 'div',
            fill : true,
            boundary : 'window'
        }}
        items={adaptor.options}
        onItemSelect={option => adaptor.set(option)}
        activeItem = {adaptor.value}
        filterable={false}
        itemRenderer={(option, {handleClick, modifiers}) =>
                <DetailedOption key={option} intent={intent} icon={icon} adaptor={adaptor} option={option} handleClick={handleClick} modifiers={modifiers} getText={getText}/>
            }>
        <Button
            intent={intent}
            icon={icon}
            rightIcon="caret-down"
            text={getText(adaptor.value)}
            disabled={adaptor.disabled}
        />
    </StringSelector>;
}

const RequestInputField : FC<RequestInputFieldProps> = props => {
    function getCaption() {
        let error : string | undefined = props.adaptor.errorMessage();
        error = error ? props.vocabulary[error] : '';
     //   const val : string = typeof (props.adaptor.value) === 'string' ? props.vocabulary[props.adaptor.value] : props.adaptor.value +'';
        const rightSide : string  =  error;
            //(props.adaptor.disabled && typeof (props.adaptor.value) !== 'boolean' ? val :'')+error;
        return <div className={'form-field--caption ' +
        (props.adaptor.errorMessage() ? getTextColor(Intent.DANGER) : getTextColor(props.intent))}>
            {props.vocabulary[props.adaptor.name] + (rightSide.length >0 ? '. '+rightSide : '') }
        </div>;
    }
    if(props.adaptor instanceof VideoFieldAdaptor){
        const adaptor: VideoFieldAdaptor = props.adaptor as VideoFieldAdaptor;
        const videoUrl : string | undefined = props.data.currentUpload !== undefined && adaptor.isIt(props.data.currentUpload.key)
            ? props.data.currentUpload.url : adaptor.getLink();
        console.log(videoUrl);
        return (
            <div>
                {getCaption()}
                {videoUrl? <video src={videoUrl}  controls width='100%'/> : (
                    <Pre>
                        {adaptor.emptyMessage ? props.vocabulary[adaptor.emptyMessage] : undefined}
                    </Pre>)
                }
                {getStringSelector(adaptor, props.intent,"video",props.vocabulary)}
                <UploadFileDialog intent={props.intent} onSuccess={adaptor.set}  type={adaptor.getLinksRequest} pathVar={adaptor.pathVar} requiredType={FileTypes.Video}/>
            </div>
        );
    }
    if(props.adaptor instanceof FileFieldAdaptor){
        const adaptor: FileFieldAdaptor = props.adaptor as FileFieldAdaptor;
        const url : string | undefined = props.data.currentUpload !== undefined && adaptor.isIt(props.data.currentUpload.key)
            ? props.data.currentUpload.url : adaptor.getLink();
        const image: boolean = adaptor.value ? isImage(adaptor.value) :false;
        const pdf : boolean = adaptor.value ? isPdf(adaptor.value) : false;
        return (
            <div>
                {getCaption()}
                {url ?
                    <div>
                        {image ? <img src={url} width='100%' alt={adaptor.name}/> : null }
                        {pdf ? <embed  src={url} width='100%'/> : null }
                        <DownloadButton url={url} intent={Intent.SUCCESS} title={new Txt().dict(start_download)}/>
                    </div>:
                    <Pre>
                        {adaptor.emptyMessage ? props.vocabulary[adaptor.emptyMessage] : undefined}
                    </Pre>
                }
                {getStringSelector(adaptor, props.intent,"folder-close",props.vocabulary)}
                <UploadFileDialog intent={props.intent} onSuccess={adaptor.set}  type={adaptor.getLinksRequest} pathVar={adaptor.pathVar}/>
            </div>
        );
    }
    if(props.adaptor instanceof SimpleOptionsFieldAdaptor){
        const adaptor  = props.adaptor as SimpleOptionsFieldAdaptor;
        return (
            <div>
                {getCaption()}
                {getStringSelector(adaptor, props.intent,"document",props.vocabulary)}
            </div>
        )
    }
    if(props.adaptor instanceof VocabularyStringField){
        const adaptor = props.adaptor as VocabularyStringField;
        return (
            <div>
                {getCaption()}
                {   <div className='tiles-container-between'>
                    <InputGroup
                        className='tiles-half-no-margin'
                        large={adaptor.disabled }
                        leftIcon={adaptor.disabled ? 'lock':undefined}
                        disabled={adaptor.disabled}
                        value={adaptor.value}
                        placeholder={props.vocabulary[props.adaptor.name] }
                        intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                        onChange={(event : React.FormEvent<HTMLInputElement>)=>{
                            adaptor.set(event.currentTarget.value);
                        }}
                    />
                    <div className='tiles-half-no-margin'>
                        {props.vocabulary[adaptor.value ? adaptor.value : key_not_found]}
                    </div>
                    </div>
                }
            </div>
        );
    }
    if(props.adaptor instanceof UniqStringField){
        const adaptor: UniqStringField = props.adaptor as UniqStringField;
        return (
            <div>
                {getCaption()}
                <InputGroup
                    large={adaptor.disabled }
                    leftIcon={adaptor.disabled ? 'lock':undefined}
                    className={adaptor.disabled ? getTextColor(props.intent) +'bp3-text-large' : undefined}
                    disabled={adaptor.disabled}
                    value={adaptor.value}
                    placeholder={props.vocabulary[props.adaptor.name] }
                    intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                    onChange={(event : React.FormEvent<HTMLInputElement>)=>{
                        adaptor.set(event.currentTarget.value);
                    }}
                />
            </div>
        );
    }

    if(props.adaptor instanceof EncodedObjectAdaptor){
        const adaptor : EncodedObjectAdaptor = props.adaptor as EncodedObjectAdaptor;
        adaptor.object.post = props.post;
        return (
            <div>
            {getCaption()}
                <FieldSaveForm
                    adaptor = {adaptor}
                    intent = {props.intent}
                    level = {props.level}
                />
            </div>
        );
    }

    if(props.adaptor instanceof ObjectArrayAdaptor){
        const adaptor : ObjectArrayAdaptor = props.adaptor as ObjectArrayAdaptor;
        if(adaptor.value) adaptor.value.forEach(value => value.post=props.post);
        return (
            <div>
                {getCaption()}
                <ArraySaveForm
                    adaptor = {adaptor}
                    intent = {props.intent}
                    level = {props.level}
                />
            </div>
        );
    }
    if(props.adaptor instanceof TagsAdaptor){
        const adaptor : TagsAdaptor  = props.adaptor as TagsAdaptor;
        return (
            <div>
                {getCaption()}
                <TagsEditField adaptor={adaptor}/>
            </div>);
    }
    if(props.adaptor instanceof UrlAdaptor){
        const adaptor = props.adaptor as UrlAdaptor;
        return (
            <div>{adaptor instanceof PictureUrlAdaptor ? getCaption() :
                <div className={'form-field--caption ' +
                (props.adaptor.errorMessage() ? getTextColor(Intent.DANGER) : getTextColor(props.intent))}>
                    {props.vocabulary[props.adaptor.name]+ " "}
                    <a href={adaptor.value} target='_blank' rel="noopener noreferrer" className='link-dark'>
                        <span className='link-dark'>
                            {adaptor.value}
                        </span>

                    </a>
                </div>
                }
                {
                    <div>
                    <InputGroup
                        large={adaptor.disabled }
                        leftIcon={adaptor.disabled ? 'lock':undefined}
                        disabled={adaptor.disabled}
                        value={adaptor.value}
                        placeholder={props.vocabulary[props.adaptor.name] }
                        intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                        onChange={(event : React.FormEvent<HTMLInputElement>)=>{
                            adaptor.set(event.currentTarget.value);
                        }}
                    />

                        {adaptor instanceof PictureUrlAdaptor ?
                            <a href={adaptor.value} target = '_blank' rel="noopener noreferrer" className='image-vitrine--container'>
                                <img src={adaptor.value} alt={adaptor.name} className='image-vitrine'/>
                            </a>:
                            null
                        }
                    </div>
                }
            </div>
        );
    }
    if(typeof (props.adaptor.value) === 'string'){
        const adaptor: FormFieldAdaptor<string> = props.adaptor as FormFieldAdaptor<string>;
        const isArea : boolean = props.adaptor instanceof TextFieldAdaptor;
        const mark = props.adaptor instanceof MarkedFieldAdaptor
            || (props.adaptor instanceof ValidEmailFieldAdaptor && props.adaptor.bounced) ?
                <Button intent={props.adaptor.intent} icon={props.adaptor.icon}>
                    {props.vocabulary[props.adaptor.mark!]}
                </Button> : undefined;
       return (
           <div>
               {getCaption()}
               { isArea ?
                   <TextArea
                       growVertically={true}
                       intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                       disabled={adaptor.disabled}
                       rows={(adaptor as TextFieldAdaptor).rows}
                       fill={true}
                       placeholder={props.vocabulary[props.adaptor.name] }
                       onChange={(event : React.FormEvent<HTMLTextAreaElement>)=>{
                           adaptor.set(event.currentTarget.value);
                       }}
                       value={adaptor.value}
                   />:
                   <InputGroup
                       rightElement={mark}
                       large={adaptor.disabled }
                       leftIcon={adaptor.disabled ? 'lock':undefined}
                       disabled={adaptor.disabled}
                       value={adaptor.value}
                       placeholder={props.vocabulary[props.adaptor.name] }
                       intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                       onChange={(event : React.FormEvent<HTMLInputElement>)=>{
                           adaptor.set(event.currentTarget.value);
                       }}
                   />
               }

           </div>
       );
    }

    if(typeof (props.adaptor.value) === 'number'){
        const adaptor: FormFieldAdaptor<number> = props.adaptor as FormFieldAdaptor<number>;
        return (
            <div>
                {getCaption()}
                <NumericInput
                    large={adaptor.disabled }
                    leftIcon={adaptor.disabled ? 'lock':undefined}
                    disabled={adaptor.disabled}
                    fill={true}
                    intent={adaptor.errorMessage() ?  Intent.DANGER : props.intent}
                    min={0}
                    value={adaptor.value}
                    minorStepSize={1}
                    onValueChange={(valueAsNumber: number) => {
                        adaptor.set(valueAsNumber);
                     }
                    }
                />
            </div>
        );
    }
    if(typeof (props.adaptor.value) === 'boolean' && !props.singleProduct){
        const adaptor: FormFieldAdaptor<boolean> = props.adaptor as FormFieldAdaptor<boolean>;
        return (
            <div>
                {getCaption()}
                <Checkbox label={adaptor.hint ? props.vocabulary[adaptor.hint] : undefined} checked={adaptor.value}  onChange={
                    (e : React.FormEvent<HTMLInputElement>) =>{
                        adaptor.set(!adaptor.value);
                    }
                }  disabled={adaptor.disabled}/>
            </div>
        );
    }
    if(props.adaptor instanceof FieldDivider){
        const isDark : boolean = props.level !== undefined && props.level % 2 ===0;
        const theme : string = isDark ? 'middle-back' : 'light-back';
        return <div className={theme}><Divider/> <Text className={'App bp3-text-large'}>{props.vocabulary[props.adaptor.name]} </Text><Divider/></div>
    }
    return <div></div>;
};
const mapStateToProps = (state: AppState) => ({
    data : state.data,
    vocabulary : state.data.vocabulary,
    videoLinks : state.data.videoLinks,
    fileLinks : state.data.fileLinks
});
export default connect(
    mapStateToProps
)(memo(RequestInputField));