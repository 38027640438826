import React,{FC} from "react";
import {Vocabulary} from "../../model/AppData";
import {Button, Card, Intent, Spinner} from "@blueprintjs/core";
import {
    all_downloads,
    all_product_downloads,
    download_from_site
} from "../../text/Literals";
import {getDownloadsUrl} from "../../api/ServerAddress";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {FullBucketDescription} from "../../service/S3";
import {TrialProduct} from "../../service/TrialProducts";

interface DownloadPanelProps{
    vocabulary : Vocabulary,
    downloads ?: FullBucketDescription,
    product ?: TrialProduct
}

const DownloadPanel : FC<DownloadPanelProps> = props => {
    console.log(props.downloads)
    return <Card className='product-download dark-back'>
        {props.product ? props.vocabulary[props.product.product_for_teachers] : null}
        {
            props.product && props.downloads && props.downloads.Objects[props.product.downloadProduct] && props.downloads.Objects[props.product.downloadProduct].length > 0?
                <a href={props.downloads.bucket + props.downloads.Objects[props.product.downloadProduct][0].Key} className='block' target="_blank" rel="noopener noreferrer">
                    <Button intent={Intent.PRIMARY} className='fill-width base-margin'>
                        {props.vocabulary[download_from_site]}
                    </Button>
                </a> : props.product ?  <Spinner intent={Intent.PRIMARY}/> : null
        }

        {props.product ? <div> {props.vocabulary[props.product.product_for_students]}
                <a className='app-store-link' target="_blank" href='https://play.google.com/store/apps/details?id=com.ScientificEntertainment.SchoolDrozdov&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noopener noreferrer">
                    <img alt='Доступно в Google Play' width='200px' src='/google-play-badge.png'/></a>
            </div>
            : null}
        <div>
            <a className='app-store-link' target="_blank" href='https://www.rustore.ru/catalog/app/com.ScientificEntertainment.SchoolDrozdov' rel="noopener noreferrer">
                <img alt='Доступно в RuStore' width='200px' src='/ru-store-badge.svg'/>
            </a>
        </div>
        {props.vocabulary[all_product_downloads]}
        <a href={getDownloadsUrl()} className='block' target="_blank" rel="noopener noreferrer">
            <Button intent={Intent.PRIMARY} className='fill-width base-margin'>
                {props.vocabulary[all_downloads]}
            </Button>
        </a>
    </Card>
}

const mapStateToProps = (state : AppState)=>({vocabulary : state.data.vocabulary, downloads : state.data.downloads})

export default connect(mapStateToProps)(DownloadPanel);